import { lazy, Suspense } from "react";

const OrganizationPage = lazy(() => import("pages/OrganizationPage"));

export const sharedRoutes = [
    {
        path: "organization/:name",
        element: (
            <Suspense fallback={<></>}>
                <OrganizationPage />
            </Suspense>
        ),
        children: []
    }
];
