import { lazy, Suspense } from "react";
import { sharedRoutes } from "./sharedRoutes";

const LoginPage = lazy(() => import("pages/LoginPage"));

export const publicRoutes = [
    ...sharedRoutes,
    {
        path: "*",
        element: (
            <Suspense fallback={<></>}>
                <LoginPage />
            </Suspense>
        ),
        children: []
    }
];
