import { lazy, Suspense } from "react";
import { sharedRoutes } from "./sharedRoutes";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutesLayout = lazy(() => import("layouts/PrivateRoutesLayout"));
const OpenPage = lazy(() => import("pages/CirculationPage/OpenPage"));
const PickedPage = lazy(() => import("pages/CirculationPage/PickedPage"));
const AcceptedPage = lazy(() => import("pages/CirculationPage/AcceptedPage"));
const UndeliverablesPage = lazy(() => import("pages/CirculationPage/UndeliverablesPage"));
const CirculationDetailPage = lazy(() => import("pages/CirculationDetailPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));

export const privateRoutes = [
    {
        key: "root",
        path: "/",
        element: <PrivateRoutesLayout />,
        children: [
            {
                key: "home",
                index: true,
                inMenu: false,
                element: <Navigate to="/open" />,
                scopes: [],
                roles: [],
                children: []
            },
            {
                key: "openRoot",
                path: "/open",
                title: "navigation.open",
                inMenu: true,
                element: <Outlet />,
                scopes: [],
                roles: [],
                children: [
                    {
                        key: "open",
                        index: true,
                        element: (
                            <Suspense fallback={<></>}>
                                <OpenPage />
                            </Suspense>
                        ),
                        scopes: [],
                        roles: [],
                        children: []
                    },
                    {
                        key: "openDetail",
                        path: ":identifier",
                        inMenu: false,
                        element: (
                            <Suspense fallback={<></>}>
                                <CirculationDetailPage />
                            </Suspense>
                        ),
                        scopes: [],
                        roles: [],
                        children: []
                    }
                ]
            },
            {
                key: "pickedRoot",
                path: "/picked",
                title: "navigation.picked",
                inMenu: true,
                element: <Outlet />,
                scopes: [],
                roles: [],
                children: [
                    {
                        key: "picked",
                        index: true,
                        element: (
                            <Suspense fallback={<></>}>
                                <PickedPage />
                            </Suspense>
                        ),
                        scopes: [],
                        roles: [],
                        children: []
                    },
                    {
                        key: "pickedDetail",
                        path: ":identifier",
                        inMenu: false,
                        element: (
                            <Suspense fallback={<></>}>
                                <CirculationDetailPage />
                            </Suspense>
                        ),
                        scopes: [],
                        roles: [],
                        children: []
                    }
                ]
            },
            {
                key: "accepted",
                path: "/accepted",
                title: "navigation.completed",
                inMenu: true,
                element: (
                    <Suspense fallback={<></>}>
                        <AcceptedPage />
                    </Suspense>
                ),
                scopes: [],
                roles: [],
                children: []
            },
            {
                key: "undeliverables",
                path: "/undeliverables",
                title: "navigation.undeliverable",
                inMenu: true,
                element: (
                    <Suspense fallback={<></>}>
                        <UndeliverablesPage />
                    </Suspense>
                ),
                scopes: [],
                roles: ["Admin", "Manager"],
                children: []
            },
            {
                key: "profile",
                path: "profile",
                inMenu: false,
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                scopes: [],
                roles: [],
                children: []
            },
            ...sharedRoutes
        ]
    },
    {
        path: "*",
        element: (
            <Suspense fallback={<></>}>
                <NotFoundPage />
            </Suspense>
        ),
        children: []
    }
];
